import './App.css';
import "@vkontakte/vkui/dist/vkui.css";
import {AppRoot, Div, Group, PanelHeader, Header, Button} from '@vkontakte/vkui';
import {coctails} from './definitions';
import {Coctail} from './components';
import {useState} from 'react';

function randomInteger(min, max) {
  // получить случайное число от (min-0.5) до (max+0.5)
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
}

function App() {
  const [random, setRandom] = useState(false);
  return (
    <AppRoot>
      <div>
        <div>
          <PanelHeader>MABAR</PanelHeader>
          <div style={{ padding: 20, color: 'red', fontSize: 30 + 'px', fontWeight: 'bold'}}>
            Обойдёмся сегодня без stories в instagram?<br/>
            Убедительная просьба: фотографируемся дома.
          </div>
          <Group header={<Header mode="secondary">Меню</Header>}>
            <Div>
              <Button size="l" onClick={() => {
                setRandom(randomInteger(0, Object.keys(coctails).length - 1));
              }}>
                Случайный выбор
              </Button>

              {random && (
                <div className="gridCoctails" style={{ marginTop: 24 }}>
                  {[Object.keys(coctails)[random]].map((key_coctail) => {
                    const _coctail = coctails[key_coctail];
                    return (
                      <Coctail key={key_coctail} coctail={_coctail}/>
                    )
                  })}
                </div>
              )}

            </Div>
            <Div>
              <div className="gridCoctails">
                {Object.keys(coctails).map((key_coctail) => {
                  const _coctail = coctails[key_coctail];
                  return (
                    <Coctail key={key_coctail} coctail={_coctail}/>
                  )
                })}
              </div>
            </Div>
          </Group>
        </div>
      </div>
    </AppRoot>
  );
}

export default App;
