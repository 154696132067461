import React from 'react';
import {ContentCard} from '@vkontakte/vkui';

const CoctailComponent = (props) => {
  const {coctail} = props;
  const {name: _name, image: _image, marja, needPrice} = coctail;
  let endPrice = 0;

  coctail.ingridients.forEach((ingridient) => {
    const {price} = ingridient.item;
    endPrice = endPrice + ((price / 1000) * ingridient.quantity);
  });

  endPrice = !!needPrice ? needPrice : endPrice + ((endPrice / 100) * marja);
  endPrice = Math.ceil(endPrice);

  function MyRound10(val) {
    return Math.round(val / 10) * 10;
  }

  endPrice = MyRound10(endPrice);

  const subtitle = (<div><div>Себестоимость</div><strong style={{color: 'red', fontSize: 30}}><br/>{endPrice} ₽</strong></div>);


  return (
    <div>
      <ContentCard
        src={_image}
        subtitle={subtitle}
        header={_name}
        text={(
          <div style={{ height: 100, overflow: 'hidden', overflowY: 'auto', boxShadow: 'inset 0 0 15px 0 rgba(0,0,0,0.1)', padding: '8px 16px', borderRadius: 4}}>
            <div style={{ fontSize: 12 }}>
              Ингридиенты:
            </div>
            {coctail.ingridients.map((ingridient) => {
              const {name, price} = ingridient.item;
              return (
                <div style={{ fontSize: 12 }}>
                  {name} {ingridient.quantity}
                </div>
              )
            })}
          </div>
        )}
        maxHeight={230}
      />
    </div>
  )
};

export const Coctail = React.memo(CoctailComponent);
