const syrop_default_price = 400;
const juice_default_price = 100;
const gaz_default_price = 100;

export const prices1000ml = {
  'vodka': {
    name: 'Водка',
    price: 850
  },
  'tequila': {
    name: 'Текила',
    price: 1930,
  },
  'tequila_indiana': {
    name: 'Текила',
    price: 1285,
  },
  'triple_sec': {
    name: 'Трипл сек',
    price: 1800,
  },
  'viski': {
    name: 'Виски',
    price: 1500,
  },
  'white_rom': {
    name: 'Ром Светлый',
    price: 1336,
  },
  'black_rom': {
    name: 'Ром Тёмный',
    price: 1600,
  },
  'gin': {
    name: 'Джин',
    price: 1142,
  },
  'liquor_coffee': {
    name: 'Кофейный ликёр',
    price: 800,
  },
  'liquor_cream': {
    name: 'Сливочный ликёр',
    price: 800,
  },
  'liquor_zemlynichn': {
    name: 'Земляничный ликёр',
    price: 800,
  },
  'liquor_kokos': {
    name: 'Кокосовый ликёр',
    price: 800,
  },
  'lemon_juice': {
    name: 'Лимон/лайм сок',
    price: 500,
  },
  'syrop_apelcin': {
    name: 'Сироп апельсиновый',
    price: syrop_default_price,
  },
  'syrop_bubble_gum': {
    name: 'Сироп бабл-гам',
    price: syrop_default_price,
  },
  'syrop_dinya': {
    name: 'Сироп дыня',
    price: syrop_default_price,
  },
  'syrop_blue_curasao': {
    name: 'Сироп блю-кюрасао',
    price: syrop_default_price,
  },
  'syrop_grenadin': {
    name: 'Сироп гренадин',
    price: syrop_default_price,
  },
  'syrop_sugar': {
    name: 'Сироп сахарный',
    price: syrop_default_price,
  },
  'syrop_mohito': {
    name: 'Сироп мохито',
    price: syrop_default_price,
  },
  'syrop_mango': {
    name: 'Сироп манго',
    price: syrop_default_price,
  },
  'syrop_qiwi': {
    name: 'Сироп киви',
    price: syrop_default_price,
  },
  'syrop_arbuz': {
    name: 'Сироп арбузный',
    price: syrop_default_price,
  },
  'syrop_lavanda': {
    name: 'Сироп лавандовый',
    price: syrop_default_price,
  },
  'imbir_syrop': {
    name: 'Сироп имбирный',
    price: 420,
  },
  'banan_syrop': {
    name: 'Сироп банановый',
    price: syrop_default_price,
  },
  'persik_syrop': {
    name: 'Сироп персиковый',
    price: syrop_default_price,
  },
  'syrop_zemlyanika': {
    name: 'Сироп земляничный',
    price: syrop_default_price,
  },
  'syrop_kokos': {
    name: 'Сироп кокосовый',
    price: syrop_default_price,
  },
  'coca_cola': {
    name: 'Кола',
    price: gaz_default_price,
  },
  'sprite': {
    name: 'Спрайт',
    price: gaz_default_price,
  },
  'soda': {
    name: 'Содовая',
    price: 80,
  },
  'juice_apelcin': {
    name: 'Апельсиновый сок',
    price: juice_default_price,
  },
  'juice_visnya': {
    name: 'Вишневый сок',
    price: juice_default_price,
  },
  'juice_mors': {
    name: 'Морс',
    price: juice_default_price,
  },
  'juice_yabloko': {
    name: 'Яблочный сок',
    price: juice_default_price,
  },
  'juice_banan': {
    name: 'Банановый сок',
    price: juice_default_price,
  },
  'juice_persik': {
    name: 'Персиковый сок',
    price: juice_default_price,
  },
  'juice_ananas': {
    name: 'Ананасовый сок',
    price: juice_default_price,
  },
  'juice_granat': {
    name: 'Гранатовый сок',
    price: juice_default_price,
  },
  'juice_tomat': {
    name: 'Томатный сок',
    price: 130,
  },
  'adrenalin': {
    name: 'Адреналин',
    price: 260,
  },
  'lemon': {
    name: 'Лимон',
    price: 100,
  },
  'slivki': {
    name: 'Сливки',
    price: 850,
  },
  'red_perec': {
    name: 'Красный перец',
    price: 500
  },
  'black_perec': {
    name: 'Чёрный перец',
    price: 500
  },
  'tabasco': {
    name: 'Табаско',
    price: 4000
  },
  'champagne_lambrusko': {
    name: 'Шампанское ламбруско',
    price: 300
  },
  'champagne_santo_stef': {
    name: 'Шампанское Санто Стефано',
    price: 300
  },
  'nastoyka_bitter': {
    name: 'Настойка',
    price: 500
  },
  'egermeyster': {
    name: 'Jagermeister',
    price: 1800
  },
  absent: {
    name: 'Абсент',
    price: 800
  },
  jagermayster: {
    name: 'Егермейстер',
    price: 2000
  }
};
