import { prices1000ml as prices } from './';

export const coctails = {
  'dermo_na_trave': {
    priority: 0,
    marja: 65,
    needPrice: 100,
    name: 'Горящее дерьмо на траве',
    image: 'https://sun9-80.userapi.com/impf/vpvHvfcVaBbUCEoo2YVuxX1ruqCtLcqy2qn3iQ/rzhd0nynOSk.jpg?size=510x340&quality=96&sign=a32ed659263a5d34bb920af3e564b677&type=album',
    ingridients: [
      {
        item: prices.syrop_dinya,
        quantity: 15,
      },
      {
        item: prices.liquor_coffee,
        quantity: 15,
      },
      {
        item: prices.triple_sec,
        quantity: 20,
      },
    ]
  },
  'banan_shot': {
    priority: 0,
    marja: 60,
    needPrice: 70,
    name: 'Топ банана',
    image: 'https://ru.inshaker.com/uploads/cocktail/promo/888/1542205503-Baby-_promo.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 15,
      },
      {
        item: prices.liquor_coffee,
        quantity: 10,
      },
      {
        item: prices.banan_syrop,
        quantity: 15,
      },
    ]
  },
  'green_feya': {
    priority: 0,
    marja: 50,
    name: 'Зелёная фея',
    image: 'https://disko-kafe.ru/upload/iblock/209/zelenaya_feya.jpg',
    ingridients: [
      {
        item: prices.syrop_dinya,
        quantity: 30,
      },
      {
        item: prices.vodka,
        quantity: 40,
      },
      {
        item: prices.absent,
        quantity: 20,
      },
      {
        item: prices.lemon_juice,
        quantity: 30,
      },
      {
        item: prices.adrenalin,
        quantity: 100,
      },
      {
        item: prices.syrop_sugar,
        quantity: 15,
      },
      {
        item: prices.syrop_blue_curasao,
        quantity: 10,
      },
      {
        item: prices.lemon,
        quantity: 10,
      },
    ]
  },
  'german_vacanty': {
    priority: 0,
    marja: 55,
    name: 'Немецкие каникулы',
    image: 'https://www.edim.tv/img/large/german-vacation.jpg',
    ingridients: [
      {
        item: prices.egermeyster,
        quantity: 30,
      },
      {
        item: prices.black_rom,
        quantity: 30,
      },
      {
        item: prices.imbir_syrop,
        quantity: 23,
      },
      {
        item: prices.lemon_juice,
        quantity: 23,
      },
      {
        item: prices.nastoyka_bitter,
        quantity: 5,
      },
      {
        item: prices.lemon,
        quantity: 10,
      },
    ]
  },
  'jager_shop': {
    priority: 0,
    marja: 0,
    name: 'Ледяной шот егеря',
    image: 'https://images.ctfassets.net/eqr0uyc2m7s4/18KQiP45g4gEKcMCqAS04a/83684d11d167408c2cfd74b63ffe3c04/Jager2018_Cocktails_GreenShot.jpg',
    ingridients: [
      {
        item: prices.jagermayster,
        quantity: 50,
      },
    ]
  },
  'tequila': {
    priority: 0,
    marja: 30,
    name: 'Шот текилы',
    image: 'https://alex-bootlegger.com/image/catalog/staty/tequila.jpg',
    ingridients: [
      {
        item: prices.tequila_indiana,
        quantity: 50,
      },
    ]
  },
  'buble_gam': {
    priority: 0,
    marja: 50,
    name: 'Бабл-гам',
    image: 'https://gradusinfo.ru/wp-content/uploads/2018/09/koktejl-bubble-gum-americano-3.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 50,
      },
      {
        item: prices.syrop_bubble_gum,
        quantity: 20,
      },
      {
        item: prices.juice_yabloko,
        quantity: 30,
      },
      {
        item: prices.sprite,
        quantity: 30,
      },
      {
        item: prices.syrop_grenadin,
        quantity: 15,
      },
    ]
  },
  'whiskey_vishnya': {
    priority: 0,
    marja: 30,
    name: 'Вишнёвый виски',
    image: 'https://ru.inshaker.com/uploads/cocktail/hires/265/1557238286-Obsession-highres.jpg',
    ingridients: [
      {
        item: prices.viski,
        quantity: 50,
      },
      {
        item: prices.juice_visnya,
        quantity: 130,
      },
      {
        item: prices.lemon_juice,
        quantity: 20,
      },
      {
        item: prices.lemon,
        quantity: 20,
      },
    ]
  },
  'otvertka': {
    priority: 0,
    marja: 50,
    name: 'Отвёртка',
    image: 'https://foodman.club/wp-content/uploads/2017/12/%D0%BE%D1%82%D0%B2-1.png',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 50,
      },
      {
        item: prices.juice_apelcin,
        quantity: 100,
      },
    ]
  },
  'london_svigle': {
    priority: 0,
    marja: 50,
    name: 'Лондонский свизл',
    image: 'https://avatars.mds.yandex.net/get-zen_doc/1107063/pub_5c5c1da807b3dd00ade878ba_5c5c72c45deb9800afcb8fa9/scale_1200',
    ingridients: [
      {
        item: prices.gin,
        quantity: 50,
      },
      {
        item: prices.syrop_sugar,
        quantity: 20,
      },
      {
        item: prices.lemon_juice,
        quantity: 15,
      },
      {
        item: prices.soda,
        quantity: 100,
      },
      {
        item: prices.lemon,
        quantity: 10,
      },
    ]
  },
  'shampagne': {
    priority: 0,
    marja: 0,
    needPrice: 250,
    name: 'Бутылка шампанского',
    image: 'https://avatars.mds.yandex.net/get-zen_doc/3310860/pub_5ffb5c80fe4e686f6ad5a95f_5ffb5f13f906b168729914b1/scale_1200',
    ingridients: [
      {
        item: prices.champagne_santo_stef,
        quantity: 750,
      },
    ]
  },
  'daikiri_zemlyanich': {
    priority: 0,
    marja: 46,
    name: 'Земляничный дайкири',
    image: 'https://static.1000.menu/cookme/images/recipe/klubnichnji-daikiri_1288114551_0.jpg',
    ingridients: [
      {
        item: prices.white_rom,
        quantity: 65,
      },
      {
        item: prices.syrop_zemlyanika,
        quantity: 25,
      },
      {
        item: prices.lemon_juice,
        quantity: 25,
      },
    ]
  },
  'blue_laguna': {
    priority: 0,
    marja: 64,
    name: 'Голубая лагуна',
    image: 'https://www.edim.tv/img/large/blue-lagoon.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 50,
      },
      {
        item: prices.syrop_blue_curasao,
        quantity: 20,
      },
      {
        item: prices.lemon_juice,
        quantity: 15,
      },
      {
        item: prices.sprite,
        quantity: 150,
      },
    ]
  },
  'vodka_energy': {
    priority: 0,
    marja: 50,
    name: 'Водка энергетик',
    image: 'http://drinkinhome.ru/wp-content/uploads/2017/07/%D0%9E%D1%80%D0%B8%D0%B3%D0%B8%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D0%BA%D0%BE%D0%BA%D1%82%D0%B5%D0%B9%D0%BB%D1%8C-%E2%80%93-%D0%B2%D0%BE%D0%B4%D0%BA%D0%B0-%D0%B8-%D1%80%D0%B5%D0%B4%D0%B1%D1%83%D0%BB%D0%BE%D0%BC.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 50,
      },
      {
        item: prices.adrenalin,
        quantity: 150,
      },
      {
        item: prices.lemon,
        quantity: 40,
      },
    ]
  },
  'blood_mary': {
    priority: 0,
    marja: 50,
    name: 'Кровавая мэри',
    image: 'https://www.edim.tv/img/large/bloody-mary-trio.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 50,
      },
      {
        item: prices.juice_tomat,
        quantity: 120,
      },
      {
        item: prices.lemon_juice,
        quantity: 10,
      },
      {
        item: prices.tabasco,
        quantity: 4,
      },
      {
        item: prices.black_perec,
        quantity: 3,
      },
    ]
  },
  'juice': {
    priority: 0,
    marja: 0,
    name: 'Любой сок 1л',
    image: 'https://zira.uz/wp-content/uploads/2018/03/soki-4.jpg',
    ingridients: [
      {
        item: prices.juice_ananas,
        quantity: 1000,
      },
    ]
  },
  'whiskey_bottle': {
    priority: 0,
    marja: 0,
    name: 'Бутылка Вильям 0.7',
    image: 'http://1.bp.blogspot.com/-FPCAqzVMtxQ/UNT3W8-MHDI/AAAAAAAABR0/lfULwVaM28M/s1600/%D0%A0%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D0%B0%D1%8F+%D0%B0%D0%BA%D1%86%D0%B8%D1%8F+%D0%B2%D0%B8%D1%81%D0%BA%D0%B8+%C2%ABWilliam+Lawsons%C2%BB+(%D0%92%D0%B8%D0%BB%D1%8C%D1%8F%D0%BC+%D0%9B%D0%BE%D1%83%D1%81%D0%BE%D0%BD%D1%81)+%C2%AB3+%D0%BF%D0%BE+%D1%86%D0%B5%D0%BD%D0%B5+2%C2%BB.jpg',
    ingridients: [
      {
        item: prices.viski,
        quantity: 700,
      },
    ]
  },
  'whiskey_bottle_500': {
    priority: 0,
    marja: 0,
    name: 'Бутылка Вильям 0.5',
    image: 'https://st4.depositphotos.com/12840000/38602/i/1600/depositphotos_386027380-stock-photo-tyumen-russia-june-05-2020.jpg',
    ingridients: [
      {
        item: prices.viski,
        quantity: 500,
      },
    ]
  },
  'shot_viski': {
    priority: 0,
    marja: 30,
    name: 'Шот виски',
    image: 'https://media.istockphoto.com/photos/shot-of-whiskey-picture-id175400315',
    ingridients: [
      {
        item: prices.viski,
        quantity: 40,
      },
      {
        item: prices.lemon,
        quantity: 5,
      },
    ]
  },
  'shot_vodka': {
    priority: 0,
    marja: 50,
    name: 'Шот водки',
    image: 'https://media.istockphoto.com/photos/vodka-shot-picture-id483656903',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 40,
      },
      {
        item: prices.lemon,
        quantity: 5,
      },
    ]
  },
  'viski_cola': {
    priority: 0,
    marja: 50,
    name: 'Виски-кола',
    image: 'https://alcofan.com/wp-content/uploads/2012/03/koktejl-viski-s-koloj.jpg',
    ingridients: [
      {
        item: prices.viski,
        quantity: 50,
      },
      {
        item: prices.coca_cola,
        quantity: 50,
      },
    ]
  },
  'vodka_cola': {
    priority: 0,
    marja: 40,
    name: 'Водка-кола',
    image: 'https://drink-mixer.com/wp-content/uploads/2020/12/whiskey-cola-lime-800x530.png',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 50,
      },
      {
        item: prices.coca_cola,
        quantity: 50,
      },
    ]
  },
  'kosta_rika': {
    priority: 0,
    marja: 55,
    name: 'Коста-рика',
    image: 'https://st3.depositphotos.com/3266419/12521/i/950/depositphotos_125216770-stock-photo-cherry-limeade-lemonade-cola-cocktail.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 50,
      },
      {
        item: prices.juice_visnya,
        quantity: 30,
      },
      {
        item: prices.coca_cola,
        quantity: 30,
      },
    ]
  },
  'daikiri': {
    priority: 0,
    marja: 50,
    name: 'Дайкири',
    image: 'https://cs10.pikabu.ru/post_img/big/2018/10/27/7/1540640966151182528.jpg',
    ingridients: [
      {
        item: prices.white_rom,
        quantity: 60,
      },
      {
        item: prices.syrop_sugar,
        quantity: 15,
      },
      {
        item: prices.lemon_juice,
        quantity: 30,
      },
    ]
  },
  'margarita': {
    priority: 0,
    marja: 50,
    name: 'Маргарита',
    image: 'https://vzboltay.com/uploads/posts/2015-03/1427206772_margarita.jpg',
    ingridients: [
      {
        item: prices.tequila,
        quantity: 50,
      },
      {
        item: prices.syrop_sugar,
        quantity: 10,
      },
      {
        item: prices.triple_sec,
        quantity: 25,
      },
      {
        item: prices.lemon_juice,
        quantity: 30,
      },
    ]
  },
  'b52': {
    priority: 0,
    marja: 60,
    name: 'Б-52',
    image: 'https://alcofan.com/wp-content/uploads/2013/05/koktejl-b-52-1.jpg',
    ingridients: [
      {
        item: prices.liquor_coffee,
        quantity: 15,
      },
      {
        item: prices.liquor_cream,
        quantity: 15,
      },
      {
        item: prices.triple_sec,
        quantity: 15,
      },
    ]
  },
  'tequila_sunrise': {
    priority: 0,
    marja: 30,
    name: 'Текила санрайз',
    image: 'https://vzboltay.com/uploads/posts/2015-03/1427122812_tequila-sunrise.jpg',
    ingridients: [
      {
        item: prices.tequila,
        quantity: 50,
      },
      {
        item: prices.syrop_grenadin,
        quantity: 10,
      },
      {
        item: prices.juice_apelcin,
        quantity: 150,
      },
    ]
  },
  'pina_colada': {
    priority: 0,
    marja: 40,
    name: 'Пина колада',
    image: 'https://s.wine.style/images_raw/pages/271548874617.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 60,
      },
      {
        item: prices.syrop_kokos,
        quantity: 20,
      },
      {
        item: prices.liquor_kokos,
        quantity: 10,
      },
      {
        item: prices.juice_ananas,
        quantity: 100,
      },
      {
        item: prices.lemon_juice,
        quantity: 20,
      },
      {
        item: prices.slivki,
        quantity: 20,
      },
    ]
  },
  'long_island': {
    priority: 0,
    marja: 50,
    name: 'Лонг Айленд',
    image: 'https://www.tvcook.ru/uploads/images/topic/2012/03/09/e2165b5271.jpg',
    ingridients: [
      {
        item: prices.vodka,
        quantity: 15,
      },
      {
        item: prices.gin,
        quantity: 15,
      },
      {
        item: prices.white_rom,
        quantity: 15,
      },
      {
        item: prices.tequila,
        quantity: 15,
      },
      {
        item: prices.triple_sec,
        quantity: 15,
      },
      {
        item: prices.syrop_sugar,
        quantity: 15,
      },
      {
        item: prices.lemon_juice,
        quantity: 15,
      },
      {
        item: prices.coca_cola,
        quantity: 50,
      },
    ]
  }
}
